@tailwind base;
@tailwind components;
@tailwind utilities;



*::active,
*::focus,
*::hover,
* {
    outline: none;
    box-shadow: none;
}
